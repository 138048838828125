
import Vue from "vue"

export default Vue.extend({
  layout: "landing",

  head () {
    return {
      link: [
        {
          rel: "preload",
          as: "font",
          crossorigin: "crossorigin",
          type: "font/woff2",
          href: "/fonts/Jost/Jost-Regular.woff2",
        },
      ],
    }
  },

  computed: {
    component () {
      return () => import("@/components/landing/demo")
    },

    style () {
      return {
        "--color-body": "#000",
        "--color-bg": "var(--color-body)",
        "--color-bg-invert": "#fff",
        "--color-text": "#fff",
        "--color-fg": "var(--color-text)",
        "--color-primary": "var(--color-text)",
        "--gradient-from": "#4162ff",
        "--gradient-to": "#dbff00",
        "--landing-font": "Jost",
        "--gradient": "linear-gradient(to right, var(--gradient-from), var(--gradient-to))",
      }
    },
  },
})
